import React, { useMemo } from 'react';
import { Form, FormInstance, Input, Row, Col, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Colorpicker } from 'antd-colorpicker';
import * as AntIcons from '@ant-design/icons';
import { Label } from '@/interfaces/label.interface';
import { validateSlugField } from '@/utils/validations';
import FilesWrapper from '../files/FilesWrapper';

export interface DynamicFormProps {
  formRef: FormInstance<Label>;
  fields?: string[];
  onSubmit?: (values: Record<string, any>) => Promise<void>;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ onSubmit, formRef, fields }) => {
  const { t } = useTranslation();
  const extraFields = useMemo(() => {
    const extraFields = [];
    if (fields?.includes('color')) {
      extraFields.push({
        label: 'Color',
        key: 'color',
        active: false,
        children: (
          <Col span={24} xs={24} md={24}>
            <Form.Item noStyle name="color">
              <Colorpicker
                onColorResult={(color) => color.hex}
                blockStyles={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        ),
      });
    }
    if (fields?.includes('image')) {
      extraFields.push({
        label: 'Image',
        key: 'image',
        children: (
          <Col span={24} xs={24} md={24}>
            <Form.Item noStyle shouldUpdate>
              {(form) => {
                const image = form.getFieldValue('image');
                return (
                  <Form.Item name="image">
                    {
                      <FilesWrapper
                        form
                        formProps={{
                          value: image,
                          dataIndex: 'path',
                          onChange: (value) => form.setFieldsValue({ image: value }),
                        }}
                      />
                    }
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        ),
      });
    }
    return extraFields;
  }, [fields]);

  const renderIcoon = (name: string) => {
    if (name !== 'default') {
      const IconComponent = (AntIcons as Record<string, any>)[name];
      return <IconComponent />;
    }

    return <span></span>;
  };

  function renderFields() {
    return fields?.map((field) => {
      switch (field) {
        case 'slug':
          return (
            <Col span={24} xs={12} md={12}>
              <Form.Item
                label={t('general.slug')}
                name="slug"
                rules={[
                  {
                    max: 30,
                    min: 4,
                    required: true,
                    type: 'string',
                    whitespace: true,
                  },
                  {
                    validator: validateSlugField,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          );
        case 'name':
        case 'description':
          return (
            <Col span={24} xs={12} md={12}>
              <Form.Item
                label={t('general.' + field)}
                name={field}
                rules={[
                  {
                    max: 30,
                    min: 4,
                    required: true,
                    type: 'string',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          );
        case 'icon':
          return (
            <Col span={24} xs={12} md={12}>
              <Form.Item shouldUpdate>
                {(form) => {
                  const iconName = form.getFieldValue('icon') || 'QuestionCircleOutlined';
                  const IconComponent = (AntIcons as Record<string, any>)[iconName];

                  return (
                    <Form.Item label={t('general.icon')} name="icon">
                      <Select
                        suffixIcon={<IconComponent />}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {Object.keys(AntIcons).map((key) => (
                          <Select.Option value={key} key={key}>
                            {renderIcoon(key)} {key}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          );
      }
    });
  }
  return (
    <Form
      form={formRef}
      name="dynamic-form"
      layout="vertical"
      autoComplete="off"
      className="dynamic-form"
      onFinish={onSubmit}
    >
      <Tabs
        style={{ width: '100%' }}
        defaultActiveKey="general"
        items={[
          {
            label: 'General',
            key: 'general',
            children: <Row gutter={[10, 10]}>{renderFields()}</Row>,
          },
          ...extraFields,
        ]}
      />
    </Form>
  );
};

export default DynamicForm;
