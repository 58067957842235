import { RawMetaCategory } from '@/interfaces/metaCategories.interface';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface DynamicTableProps {
  columns: any[];
  data?: RawMetaCategory;
  loading?: boolean;
  setModal: (value: any) => void;
  onDelete?: (catId: string, id: string) => void;
  onEdit?: (record: Record<string, string>) => void;
}
const DynamicTable: React.FC<DynamicTableProps> = ({
  columns,
  data,
  loading,
  setModal,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row justify="end" gutter={[10, 10]}>
        <Col>
          <Button
            onClick={() =>
              setModal({
                visible: true,
                type: data ? 'add' : 'create',
                data: {
                  metacategory_id: data?._id || '',
                },
              })
            }
            type="primary"
          >
            {t('general.add')}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Table
        className="custom-table"
        rowKey={(row) => row._id}
        columns={[
          ...columns,
          {
            title: t('general.actions'),
            key: '_id',
            render: (txt, record: Record<string, string>) => (
              <Row justify="start" gutter={[10, 10]}>
                <Col>
                  <Button
                    onClick={() =>
                      onEdit?.({
                        ...record,
                        metacategory_id: data?._id || '',
                      })
                    }
                    type="primary"
                    icon={<EditOutlined />}
                  />
                </Col>
                <Col>
                  <Button
                    onClick={() => onDelete?.(data?._id || '', record._id)}
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Col>
              </Row>
            ),
          },
        ]}
        dataSource={data?.records}
        loading={loading}
        pagination={{
          total: data?.records.length,
          defaultPageSize: 10,
          showTotal: (total) => t('general.total', { total }),
        }}
      />
    </>
  );
};

export default DynamicTable;
