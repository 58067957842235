import {
  Badge,
  Breadcrumb,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Skeleton,
  Form,
  message,
} from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import * as AntIcons from '@ant-design/icons';
// Hooks
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// API
import APIService from '@/services/API';
import { useParams } from 'react-router-dom';
import DynamicTable from '@/components/utils/DynamicTable';
import DynamicForm from '@/components/utils/DynamicForm';

const INITIAL_MODAL_STATE = {
  visible: false,
  type: 'create', // create | update | add
  data: {},
};
const DynamicCatalogScreen = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { id = '' } = useParams();
  const [formRef] = Form.useForm();
  const [loadingModal, setLoadingModal] = useState(false);
  const [modal, setModal] = useState<{
    visible: boolean;
    type: string;
    data: Record<string, string>;
  }>(INITIAL_MODAL_STATE);
  const { isLoading, data } = useQuery(['dynamic-catalog', id], () =>
    APIService.getCatalog(id),
  );
  const slug = useMemo(() => {
    const findSlug = data?.configurations.find((row) => row.language === language);
    return findSlug?.slug || 'none';
  }, [data?.configurations, language]);

  const {
    isLoading: TableLoading,
    data: TableData,
    refetch,
  } = useQuery(['dynamic-meta', slug], () =>
    APIService.getMetaCategories({
      slug,
    }).then((res) => res?.data[0]),
  );

  const translationTitle = data?.configurations.find((row) => row.language === language);

  const handleSubmitForm = async (values: Record<string, string>) => {
    setLoadingModal(true);
    let response;

    if (modal.type === 'create') {
      response = await APIService.createMetaCategory({
        category_id: id,
        slug,
        records: [values],
      });

      if (response.ok) {
        refetch();
        message.success(t('general.createSuccess'));
      } else {
        message.error(t('general.createError'));
      }
    } else if (modal.type === 'update') {
      const body: Record<string, any> = {};

      for (const key in values) {
        body[`records.$.${key}`] = values[key];
      }

      response = await APIService.updateMetaCategory(
        modal.data.metacategory_id,
        {
          $set: body,
        },
        {
          'records._id': modal.data._id,
        },
      );

      if (response.ok) {
        refetch();
        message.success(t('general.updateSuccess'));
      } else {
        message.error(t('general.updateError'));
      }
    } else if (modal.type === 'add') {
      response = await APIService.updateMetaCategory(modal.data.metacategory_id, {
        $push: {
          records: values,
        },
      });

      if (response.ok) {
        refetch();
        message.success(t('general.createSuccess'));
      } else {
        message.error(t('general.createError'));
      }
    }

    setModal(INITIAL_MODAL_STATE);
    formRef.resetFields();
    setLoadingModal(false);
  };

  const handleDelete = async (catId: string, targetId: string) => {
    const response = await APIService.updateMetaCategory(catId, {
      $pull: {
        records: {
          _id: targetId,
        },
      },
    });
    if (response.ok) {
      refetch();
      message.success(t('general.deleteSuccess'));
    } else {
      message.error(t('general.deleteError'));
    }
  };
  if (isLoading) {
    return (
      <div className="dashboard-screen fadeIn">
        <Card>
          <Row gutter={[10, 10]} className="fadeIn">
            <Col>
              <Skeleton />
            </Col>
            <Col>
              <Skeleton active />
            </Col>
            <Col>
              <Skeleton active />
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
  console.log('Table data: ', TableData);
  return (
    <div className="dashboard-screen fadeIn">
      <Modal
        open={modal.visible}
        zIndex={999}
        onCancel={() => {
          setModal(INITIAL_MODAL_STATE);
          formRef.resetFields();
        }}
        okButtonProps={{
          loading: loadingModal,
          onClick: () => {
            formRef.submit();
          },
        }}
        centered
      >
        <DynamicForm
          fields={data?.field_config}
          formRef={formRef}
          onSubmit={handleSubmitForm}
        />
      </Modal>
      <Card>
        <Row justify="space-between">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <span>{translationTitle?.name}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>

        <DynamicTable
          setModal={setModal}
          onEdit={(data) => {
            formRef.setFieldsValue(data);
            setModal({
              visible: true,
              type: 'update',
              data,
            });
          }}
          onDelete={(catId, id) =>
            Modal.confirm({
              title: t('general.deleteConfirmation'),
              okText: t('general.yes'),
              onOk: () => handleDelete(catId, id),
            })
          }
          columns={
            data?.field_config.map((row) => ({
              title: t(`general.${row}`),
              key: row,
              dataIndex: row,
              render: (text: string) => {
                if (text) {
                  if (row === 'color') {
                    return <Badge color={text} text={text} />;
                  } else if (row === 'icon') {
                    const IconComponent = (AntIcons as Record<string, any>)[text];
                    return <IconComponent />;
                  } else if (row === 'image') {
                    return <Image height={40} src={text} />;
                  }

                  return <span>{text}</span>;
                }

                return <></>;
              },
            })) || []
          }
          data={TableData}
          loading={TableLoading}
        />
      </Card>
    </div>
  );
};

export default DynamicCatalogScreen;
